import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

export const StyledHeader = styled.header`
  width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 144px 0 280px;
  z-index: 5;

  h1 {
    font-size: 80px;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 24px 0 144px;
    h1 {
      font-size: 32px;
    }
  }
`;

export const Image = styled.img`
  width: 400px;
  margin-left: 40px;

  @media (max-width: 768px) {
    margin: 40px 0 30px;
  }
`;

const SubheaderP = styled.p`
  margin-bottom: 2rem;
  text-align: center;
  padding: 0 160px;
  font-size: 21px;
  font-weight: 500;

  @media (max-width: 768px) {
    padding: 0 24px;
    font-size: 14px;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;

  & > div,
  & > ul {
    flex: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    text-align: center;
  }
`;

export const Container = styled.div`
  width: 1000px;
  max-width: 100%;
  padding: 0 20px;
  margin: 0 auto;
`;

export const Button = styled.button<{
  $bg?: string;
  $color?: string;
}>`
  border-radius: 3rem;
  border: 1px solid ${(props) => props.$bg || '#fff'};
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 24px;
  background-color: ${(props) => props.$bg || '#fff'};
  color: ${(props) => props.$color || '#1d1d1f'};
  transition: all 0.2s ease;

  &:hover {
    background-color: #3d3d3d;
  }

  @media (max-width: 786px) {
    font-size: 12px;
  }
`;

export const SecondaryButton = styled.button`
  border-radius: 3rem;
  font-weight: 500;
  border: 1px solid #cad0d9;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 24px;
  background-color: #fff;
  color: #1d1d1f;
  transition: all 0.2s ease;

  &:hover {
    border: 1px solid #a3a3a3;
  }

  @media (max-width: 786px) {
    font-size: 12px;
  }
`;

const Hero = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  // Effect to update the state when window is resized
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      id='home-hero'
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <StyledHeader>
        <a href='https://forms.gle/Xw3teP8uBeAeLWRp7'>
          <div
            className='new-badge'
            style={{
              marginBottom: '2rem',
              backgroundColor: '#f3f4f6',
              borderRadius: '32px',
              padding: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: '#393e47',
                borderRadius: '32px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '8px',
              }}
            >
              <p
                style={{
                  marginBottom: 0,
                  color: '#ffffff',
                  padding: '3px 10px',
                  fontWeight: '500',
                }}
              >
                New
              </p>
            </div>
            <p
              style={{
                marginBottom: 0,
                color: '#232529',
                fontWeight: '500',
              }}
            >
              {isMobile
                ? 'AI EAA complience agent'
                : 'Our AI-powered EAA complience agent is live! 🎉'}
            </p>
            <div className='chevron-right'>
              <ChevronRightRoundedIcon
                sx={{ fontSize: 24, color: '#393e47' }}
              />
            </div>
          </div>
        </a>

        <h1
          style={{
            marginBottom: '1.5rem',
            textAlign: 'center',
          }}
        >
          Accessibility magic.
        </h1>

        <SubheaderP>
          Reach more clients, avoid costly fines, accelerate development.
          A11yfuel uses AI to scan and fix your website's accessibility issues
          and ensure compliancy.
        </SubheaderP>

        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '16px' }}>
            <a href='https://forms.gle/Xw3teP8uBeAeLWRp7' target='blank'>
              <Button $bg='#1d1d1f' $color='#ffffff'>
                Join waitlist
              </Button>
            </a>
          </div>

          <a href='https://calendly.com/allyfuel/30min' target='blank'>
            <SecondaryButton>Book a Demo Call</SecondaryButton>
          </a>
        </div>
      </StyledHeader>
    </div>
  );
};

export default Hero;
