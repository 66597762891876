import React from 'react';
import DisabilityList from './DisabilityList';

const TransparentCard = ({
  title,
  description,
  imageSrc,
}: {
  title: string;
  description: string;
  imageSrc: string;
}) => {
  return (
    <div className='card-value'>
      {imageSrc !== '' && (
        <div
          className='auditapp'
          style={{
            border: '1px solid #e7e7e7',
            borderRadius: '16px',
            overflow: 'hidden',
          }}
        >
          <img src={imageSrc} alt='A11yfuel benefit' />
        </div>
      )}
      {imageSrc === '' && <DisabilityList />}

      <div className='ally-card-text'>
        <h1>{title}</h1>
        <p className='description-card'>{description}</p>
      </div>
    </div>
  );
};

export default TransparentCard;
