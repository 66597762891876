import React from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'react-bootstrap';

const ProblemsWrapper = styled.div`
  /* position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)),
    url('/img/PletavaWork.jpg') no-repeat center center;
  background-size: cover;
  background-attachment: fixed; */
  padding: 32px;
  padding-top: 0;

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const CardWrapper = styled.div`
  background: #f7f7f7;
  padding: 32px;
  margin: 16px;
  border-radius: 16px;
  /* box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5); */
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  /* backdrop-filter: blur(8px); */

  @media (max-width: 768px) {
    margin: 16px 0;
  }
`;

const Card = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <Col xs={12} sm={6} md={6}>
    <CardWrapper>
      <h3 style={{ marginBottom: '16px', fontWeight: 600, color: '#252a33' }}>
        {title}
      </h3>
      <p style={{ fontWeight: 500, lineHeight: 1.4, color: '#75808a' }}>
        {description}
      </p>
    </CardWrapper>
  </Col>
);

const Problems = () => {
  return (
    <ProblemsWrapper id='problems'>
      <Container style={{ position: 'relative', zIndex: 9 }}>
        <Row>
          <Col md={12} lg={4}>
            <h1 style={{ color: '#252a33', marginTop: '16px' }}>
              Aren’t these accessibility challenges familiar?
            </h1>
          </Col>
          <Col md={12} lg={8} style={{ alignItems: 'stretch' }}>
            <Row>
              <Col md={12}>
                <Row>
                  <Card
                    title='Regulatory Fines'
                    description='The EAA is coming into force in less then a year with fines of up to €60,000 per issue. With 94% of EU websites failing accessibility standards, businesses can expect hundreds of such issues on one page.'
                  />
                  <Card
                    title='Lost Customers'
                    description='Globally, 1.3 billion people, or 16% of the population, live with disabilities. Non-accessible websites exclude a massive potential user base.'
                  />
                </Row>
              </Col>
              <Col md={12}>
                <Row>
                  <Card
                    title='Drain on Resources'
                    description='Fixing accessibility issues is a nightmare, draining both time and dev capacity as most developers lack the necessary expertise.'
                  />
                  <Card
                    title='Reputation Damage'
                    description='Non-compliance leads to lawsuits, bad press, and in some EU countries like Ireland, up to 18 months in prison for company directors.'
                  />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </ProblemsWrapper>
  );
};

export default Problems;
