import React from 'react';
import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const DisBlock = styled.div`
  width: 240px;
  background-color: #f7f7f7;
  border-radius: 0.75rem;
  border: 1px solid #e7e7e7;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #525966;
    font-size: 18px;
    font-weight: 600;
    padding: 24px;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    p {
      font-size: 14px;
      padding: 16px 48px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

const DisabilityList = () => {
  return (
    <Wrapper style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Row>
        <DisBlock>
          <p>ADHD</p>
        </DisBlock>
        <DisBlock>
          <p>Dyslexia</p>
        </DisBlock>
      </Row>
      <Row>
        <DisBlock>
          <p>Deafness</p>
        </DisBlock>
        <DisBlock>
          <p>Autism</p>
        </DisBlock>
      </Row>
      <Row>
        <DisBlock>
          <p>Dyscalculia</p>
        </DisBlock>
        <DisBlock>
          <p>Dysgraphia</p>
        </DisBlock>
      </Row>
    </Wrapper>
  );
};

export default DisabilityList;
