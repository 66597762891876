import React from 'react';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Problems from '../components/Problems';
import Navbar from '../components/Navbar';
import ValueList from '../components/ValueList';
import FinalCTA from '../components/FinalCTA';
import ProductDemo from '../components/ProductDemo';

const Home = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <ProductDemo />
      <Problems />
      <ValueList />
      <FinalCTA />
      <Footer />
    </>
  );
};

export default Home;
