import React from 'react';

const ImageCard = ({
  title,
  description,
  imageSrc,
}: {
  title: string;
  description: string;
  imageSrc: string;
}) => {
  return (
    <div className='card-value card-img'>
      <div className='ally-card-text'>
        <h1>{title}</h1>
        <p className='description-card'>{description}</p>
      </div>
      <div
        className='auditapp'
        style={{
          border: '1px solid #e7e7e7',
          borderRadius: '16px',
          overflow: 'hidden',
        }}
      >
        <img src={imageSrc} alt='A11yfuel benefit' />
      </div>
    </div>
  );
};

export default ImageCard;
