import React from 'react';
import styled from 'styled-components';

const ProductDemoWrapper = styled.div`
  margin-top: -4.5%;
  margin-bottom: 80px;
  .hero-image-container {
    position: relative;
    overflow: hidden;
  }

  .hero-large-image {
    width: 100%;
    height: auto;
    display: block;
  }

  .hero-small-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    height: auto;
    z-index: 10;
  }

  @media (max-width: 768px) {
    margin-top: -40%;
  }
`;

const ProductDemo = () => {
  return (
    <ProductDemoWrapper>
      <div className='hero-image-container'>
        <img
          src='img/heroLines.svg'
          alt='Large Background'
          className='hero-large-image'
        />
        <img
          src='img/auditApp.png'
          alt='Centered Overlay'
          className='hero-small-image'
        />
      </div>
    </ProductDemoWrapper>
  );
};

export default ProductDemo;
