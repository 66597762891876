import { useCallback, useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const ContactButton = styled.button<{
  $bg?: string;
  $reverse?: boolean;
  $disabled?: boolean;
  $color?: string;
}>`
  border-radius: 16px;
  border: 2px solid ${(props) => props.$bg || '#fff'};
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.$disabled ? 0.8 : 1)};
  padding: 10px 24px;
  font-weight: 500;
  font-size: 14px;
  background-color: ${(props) =>
    props.$reverse ? 'transparent' : props.$bg || '#fff'};
  color: ${(props) => (props.$color ? '#fff' : '#252a33')};
  transition: all 0.2s ease;

  &:hover {
    background-color: ${(props) =>
      props.$disabled || props.$reverse ? props.$bg : 'transparent'};
    color: ${(props) =>
      props.$color === '#fff'
        ? '#1d1d1d'
        : props.$reverse || props.$disabled
        ? '#fff'
        : props.$color};
  }
`;

const Navbar = () => {
  const [headerStyle, setHeaderStyle] = useState('header-static');

  const listenScrollEvent = () => {
    if (window.scrollY > 48) {
      setHeaderStyle('header-shadow');
    } else {
      setHeaderStyle('header-static');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const toggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);
  return (
    <nav
      style={{
        position: 'fixed',
        borderBottom: '1px solid transparent',
        transition: 'border-bottom, background-color 0.4s ease',
        minHeight: '72px',
        width: '100%',
        lineHeight: '60px',
        fontSize: '1rem',
        zIndex: 999999,
      }}
      className={headerStyle}
    >
      <div
        className={`menu-icon ${isMenuOpen ? 'open-bar' : 'nothing'}`}
        onClick={toggleMenu}
      >
        <i></i>
        <i></i>
      </div>

      <div className='logo'>
        <Nav.Link
          to='/'
          as={NavLink}
          className='d-flex align-items-center'
          onClick={closeMenu}
        >
          <img
            alt='a11yfuel'
            src='/img/logoTransparent.png'
            width='48px'
            className='d-inline-block'
            style={{ marginRight: '4px', marginTop: '8px' }}
          />
        </Nav.Link>
      </div>

      <div className='menu'>
        <ul className={isMenuOpen ? 'showing' : ''}>
          <li className='first-menu-item'>
            <a
              href='#home-hero'
              className='nav-item-margin'
              onClick={closeMenu}
            >
              Home
            </a>
          </li>
          <li>
            <a href='#problems' className='nav-item-margin' onClick={closeMenu}>
              Problem
            </a>
          </li>
          <li>
            <a
              href='#solutions'
              className='nav-item-margin'
              onClick={closeMenu}
            >
              Solution
            </a>
          </li>
          <li>
            <a
              href='https://forms.gle/Xw3teP8uBeAeLWRp7'
              target='blank'
              className='nav-item-margin'
              onClick={closeMenu}
            >
              Join waitlist
            </a>
          </li>
          <li>
            <a
              href='https://calendly.com/allyfuel/30min'
              target='blank'
              className='nav-item-margin'
              onClick={closeMenu}
            >
              <ContactButton
                className='contact-nav-btn'
                $bg='#1d1d1f'
                $reverse={true}
                style={{
                  height: '32px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  fontSize: '14px',
                }}
              >
                Contact
              </ContactButton>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
