import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const Error = () => {
  return (
    <div
      style={{
        paddingTop: '240px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h1 style={{ color: '#252a33' }}>This page does not exist : (</h1>
      <Nav.Link
        to='/'
        as={NavLink}
        style={{
          color: '#323844',
          textDecoration: 'underline',
          fontSize: '20px',
          marginTop: '16px',
        }}
      >
        Return to the home screen
      </Nav.Link>
    </div>
  );
};

export default Error;
