import React from 'react';
import ImageCard from './ImageCard';
import TransparentCard from './TransparentCard';

const ValueList = () => {
  return (
    <div id='solutions'>
      <ImageCard
        title='Accessibility fixes has never been that easy.'
        description='Meet Ally, our AI accessibility complience agent trained on over 100k accessibility samples, delivering best-practice fixes in seconds.'
        imageSrc='./img/AllyAI.png'
      />
      <TransparentCard
        title='Track progress. Gain insights. Set up a goal.'
        description='Track detected issues and see visualizations like "Users affected", "Money at risk" and so much more.'
        imageSrc='./img/auditApp.png'
      />
      <ImageCard
        title='Integrate A11yfuel into your business.'
        description='Seamlessly integrate with your favorite project management and development tools to ensure Ally fits your business, not the other way around.'
        imageSrc='./img/githubIntegration.webp'
      />
      <TransparentCard
        title='Beyond visual impairments.'
        description='A11yfuel covers a wide range of disabilities, not just visual, to ensure your product is built for everyone.'
        imageSrc=''
      />
    </div>
  );
};

export default ValueList;
