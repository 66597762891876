import React from 'react';
import styled from 'styled-components';
import Footer from '../components/Footer';

const TermsWrapper = styled.div`
  /* background-color: #f4fbff; */
  padding: 64px 40px;
  padding-top: 80px;

  ul li {
    list-style: circle;
  }

  @media (max-width: 768px) {
    padding: 40px 0;
  }
`;

const Terms = () => {
  return (
    <TermsWrapper>
      <div style={{ marginBottom: '64px', width: '40%' }}>
        <h2>Terms of use of A11yfuel.</h2>
        <p>Last updated: 01.08.2020</p>
        <p>
          Please read these Terms and Conditions carefully before using the
          https://a11yfuel.com website operated by A11yfuel ("us", "we", or
          "our").
        </p>
        <p>A11yfuel is located at: London, UK.</p>
        <p>
          By accessing or using the Service you agree to be bound by these
          Terms. If you disagree with any part of the terms then you may not
          access the Service.
        </p>
      </div>
      <h2>Acceptable use</h2>
      <p>
        You must not use our website in any way or take any action that causes,
        or may cause, damage to the website or impairment of the performance,
        availability or accessibility of the website; use our website in any way
        that is unlawful, illegal, fraudulent or harmful, or in connection with
        any unlawful, illegal, fraudulent or harmful purpose or activity; use
        our website to copy, store, host, transmit, send, use, publish or
        distribute any material which consists of (or is linked to) any spyware,
        computer virus, Trojan horse, worm, keystroke logger, rootkit or other
        malicious computer software; conduct any systematic or automated data
        collection activities (including without limitation scraping, data
        mining, data extraction and data harvesting) on or in relation to our
        website without our express written consent; access or otherwise
        interact with our website using any robot, spider or other automated
        mean, except for the purpose of search engine indexing; violate the
        directives set out in the robots.txt file for our website; or use data
        collected from our website for any direct marketing activity (including
        without limitation email marketing, SMS marketing, telemarketing and
        direct mailing); use data collected from our website to contact
        individuals, companies or other persons or entities.
      </p>
      <h2>Cookies</h2>
      <p>
        We employ the use of cookies. By using A11yfuel's website you consent to
        the use of cookies in accordance with A11yfuel’s privacy policy. Most of
        the modern day interactive websites use cookies to enable us to retrieve
        user details for each visit. Cookies are used in some areas of our site
        to enable the functionality of this area and ease of use for those
        people visiting. Some of our advertising partners may also use cookies.
        Unless otherwise stated, A11yfuel and its licensors own the intellectual
        property rights for all material on A11yfuel. All intellectual property
        rights are reserved. You may view and print pages from
        https://a11yfuel.com for your own personal use subject to restrictions
        set in these terms and conditions.
      </p>
      <h2>Links To Other Websites</h2>
      <p>
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by A11yfuel.
      </p>
      <p>
        A11yfuel has no control over, and assumes no responsibility for, the
        content, privacy policies, or practices of any third party web sites or
        services. You further acknowledge and agree that A11yfuel shall not be
        responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with use of or
        reliance on any such content, goods or services available on or through
        any such websites or services.
      </p>
      <h2>Breaches of these terms and conditions</h2>
      <p>
        Without prejudice to our other rights under these terms and conditions,
        if you breach these terms and conditions in any way, or if we reasonably
        suspect that you have breached these terms and conditions in any way, we
        may: send you one or more formal warnings; temporarily suspend your
        access to our website;permanently prohibit you from accessing our
        website; [block computers using your IP address from accessing our
        website]; [contact any or all of your internet service providers and
        request that they block your access to our website]; commence legal
        action against you, whether for breach of contract or otherwise; and/or
        [suspend or delete your account on our website].
      </p>
      <p>
        Where we suspend or prohibit or block your access to our website or a
        part of our website, you must not take any action to circumvent such
        suspension or prohibition or blocking[ (including without limitation
        [creating and/or using a different account])].
      </p>
      <h2>Assignment</h2>
      <p>
        You hereby agree that we may assign, transfer, sub-contract or otherwise
        deal with our rights and/or obligations under these terms and
        conditions.
      </p>
      <p>
        You may not without our prior written consent assign, transfer,
        sub-contract or otherwise deal with any of your rights and/or
        obligations under these terms and conditions.
      </p>
      <h2>Third party rights</h2>
      <p>
        A contract under these terms and conditions is for our benefit and your
        benefit, and is not intended to benefit or be enforceable by any third
        party. The exercise of the parties' rights under a contract under these
        terms and conditions is not subject to the consent of any third party.
      </p>
      <h2>Changes</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material we will try to provide at
        least 30 days' notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        by email:{' '}
        <a
          style={{ display: 'inline-block', marginBottom: '8px' }}
          href='mailto:hello@a11yfuel.com'
        >
          hello@a11yfuel.com
        </a>
      </p>
      <Footer />
    </TermsWrapper>
  );
};

export default Terms;
