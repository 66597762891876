import React from 'react';
import { Button, SecondaryButton } from './Hero';

const FinalCTA = () => {
  return (
    <div
      className='final-cta'
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '80px',
      }}
    >
      <div className='final-cta-content'>
        <h1>
          Scale with <span style={{ color: '#6e6e73' }}>confidence</span>
        </h1>
        <p style={{ fontWeight: '500', margin: '16px 0', fontSize: '18px' }}>
          A11yfuel ensures you meet current and upcoming compliance requirements
          like the European Accessibility Act and more.
        </p>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '16px' }}>
            <a href='https://forms.gle/Xw3teP8uBeAeLWRp7' target='blank'>
              <Button $bg='#1d1d1f' $color='#ffffff'>
                Join waitlist
              </Button>
            </a>
          </div>
          <a href='https://calendly.com/allyfuel/30min' target='blank'>
            <SecondaryButton>Book a Demo Call</SecondaryButton>
          </a>
        </div>
      </div>
      <div
        className='final-img-group'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '80px',
            borderRadius: '50%',
            overflow: 'hidden',
            marginRight: '16px',
          }}
        >
          <img src='img/EAA.jpg' alt='European Accessibility Act' />
        </div>
        <div style={{ width: '96px', marginRight: '16px' }}>
          <img src='img/ada-logo.png' alt='ADA' />
        </div>
        <div style={{ width: '80px' }}>
          <img src='img/aoda-logo.png' alt='AODA' />
        </div>
      </div>
    </div>
  );
};

export default FinalCTA;
